* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Public Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Charis SIL', sans-serif;
  font-weight: 300;
}

p,
li {
  font-size: 22.5px;
  font-weight: 300;
  line-height: 1.5em;
  margin: 0 auto 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 2.5rem;
  list-style-type: 2.5rem;
  color: rgb(32, 36, 97);
  margin: 0;
}

h3 {
  font-size: 1.5rem;
  list-style-type: 2.5rem;
  color: #aaa;
  margin: 2rem 0;
}

h4 {
  font-size: 1.5rem;
  font-weight: 100;
  color: darkgoldenrod;
}

a {
  color: purple;
}